import { Navigate, useRoutes } from 'react-router-dom';


import {

  Page500,
  Page403,
  Page404,
  HomePage,
  CategoryDetailsPage,
  AboutPage,
  Contact,

} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([

    // Main Routes
    {
      // element: <LandingPage />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'category/:name', element: <CategoryDetailsPage /> },

      ],
    },

    {
      children: [

        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
