
// ----------------------------------------------------------------------

const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  hero: {
    title: " للتجارة الصناعية",
    blue_title: "الخيري  ",
    desc: "تصنيع هياكل حديد و الاستانلس ستيل والألومنيوم"
  },
  companies: {
    title: 'تثق بنا أفضل الشركات'
  },
  services: {
    title: "خدماتنا ",
    desc: 'تمتلك شركة خيري للتجارية القدرة على تنفيذ العديد من أساليب تشكيل المعادن وطرق الانتاج، مع وجود العديد من الأقسام المختلفة مع مكائن متنوعة، تضمن مرونة العمل لأي مشروع، كل قسم قادر على تقديم خدمات متعددة بما في ذلك:',
    items: [
      {
        icon: "mdi:soldering-iron",
        title: 'اللحام للحديد واالستانلس ستيل وااللومنيوم ',
        description: 'عمال اللحام لدينا مدربون ومعتمدون في لحام MIG، ولحام غاز التنغستن (GTAW)، ولحام البلازما (PAW)، ولحام بالأوكسي أسيتيلين (OAW)، واللحام النقطي (RSW).',
      },
      {
        icon: "logos:fabric",
        title: 'تصنيع حديد والستانلس ستيل ومنها',
        description: 'الخزانات، واللوحات الإعلانية، والدرابزين، والسلالم، والديكورات، وغيرها.',
      },
      {
        icon: "emojione-monotone:door",
        title: '	تصنيع الأبواب الحديد',
        description: ': مجموعة فرعية من التصنيع هي قدرتنا على تصنيع العديد من الأبواب من أنواع مختلفة حسب التصميم ',
      },
      {
        icon: "game-icons:shears",
        title: '	القص',
        description: 'باستخدام آلات قص متعددة، نحن قادرون على قص القضبان المستديرة، والقضبان المربعة، والأنابيب، والعوارض، والألواح المسطحة التي يصل طولها إلى 3 أمتار وسمكها 20 مم.',
      },
      {
        icon: "game-icons:laserburn",
        title: 'القطع بالليزر',
        description: 'باستخدام آلتين للقطع بالليزر بالإضافة إلى آلة بلازما عالية الطاقة، نحن قادرون على قطع مختلف الأشكال والأحجام حتى سمك 40 مم باستخدام صفائح يصل حجمها إلى 6 × 1.5 متر.',
      },
      {
        icon: "lucide:roller-coaster",
        title: 'درفلة القطاعات واالألواح المعدنيه ',
        description: 'باستخدام مجموعة متنوعة من القوالب والآلات، نحن قادرون على لف الأنابيب والجسور والألواح التي يصل سمكها إلى 20 مم وطولها 12 أمتار.',
      },
      {
        icon: "fluent-emoji-high-contrast:factory",
        title: '	تسنين',
        description:
          'يوجد عدد من الآلات التي تسمح تسنين القضبان والأنابيب المستديرة المصنوعة من الحديد والاستاليس استيل باستخدام آلة لولبة يمكنها التعامل مع قضبان وأنابيب يصل قطرها إلى 60 مم، بالإضافة إلى آلة خراطة يمكنها التعامل مع أنابيب وقضبان قطرها 300مم.',
      },
      {
        icon: "ic:twotone-devices-fold",
        title: 'ثني ألواح الحديد',
        description: 'نحن قادرون على ثني الصفائح حتى سمك 25 ملم وطول يصل إلى 6 متر لتصنيع أشكال متنوعة.',
      },
    ]
  },

  projects: {
    title: "مشاريع ",
    desc: "بعض المشاريع التى قمنا بتنفيذها",
    items: [
      {
        title: 'بوابات انفاق الحرم المكي ',
      },
      {
        title: 'داخل المسجد الحرام بمكة المكرمة ',
      },
      {
        title: 'مشروع برج الملك عبد العزيز بمكة ',
      },
      {
        title: 'جامعة الملك عبد العزيز   ',
      },
      {
        title: 'جامعة الملك عبدالله للعلوم والتقنية ',
      },
    ]
  },
  about: {
    title: "من نحن ؟",
    desc: "تأسست شركة خيري التجارية عام 1970 وتمتلك أكثر من 50 عامًا من التاريخ المبني على النجاح والتقدم في هذا المجال، وكان لدينا العديد من المشاريع على مدار الخمسين عامًا، بما في ذلك على سبيل المثال لا الحصر: الحرم المكي الشريف، مطار جدة الدولي  مشروع جامعة الملك عبد العزيز، وجامعة الملك عبد الله.",
  },

  Vision: {
    Vision_title: "الرؤية",
    Vision_desc: " أن نصبح واحدة من الشركات الرائدة في مجال تصنيع هياكل حديد و الاستاليس اسيتل والألومنيوم في الدولة بالإضافة إلى كونها شركة رائدة على المستوى الوطني/الإقليمي. المساعدة في تحقيق رؤية 2030 من خلال تقديم الجودة والسرعة لجميع المشاريع ذات الصلة مع الاستمرار في بذل العناية الواجبة عندما يتعلق الأمر بالتطور ومواكبة التغييرات الجديدة في هذا القطاع.",

    Mission_title: "المهمة",
    Mission_desc: "تقديم أفضل خدمة ممكنة عندما يتعلق الأمر بالجودة والتكلفة ومدة الإنجاز لعملائنا، باستخدام القوى العاملة الماهرة لدينا لتقديم حلول سريعة وفعالة لأي استفسارات أو مشكلات قد تنشأ، وتطوير موظفينا كقادة يمكنهم المساهمة بشكل إيجابي على أداء شركتنا وتطوير القطاع. ",

  },

  contact: {
    title: "تواصل معنا",
    desc: "سنكون سعداء بالتواصل معك",
    mail: " العنوان البريدى ",
    phone: 'رقم الهاتف',
    fax: 'رقم الفاكس',
    email: 'الايميل ',

  },

  nav: {
    home: "الصفحة الرئيسية ",
    companies: "شركات تثق بنا",
    services: "خدماتنا",
    projects: "المشاريع السابقة",
    aboutUs: "من نحن",
    contactUs: 'تواصل معنا',
    theme: 'لون الخلفية',
    language: "اللغة",
    category: 'الأقسام',
    categories: [
      // { title: "قسم الحدادة", link: "steel", desc: "" },
      { title: "قسم الصناعات المعدنية و تشغيل المعادن", link: "steel", desc: "" },
      { title: "قسم اللحام للحديد واالستانلس ستيل وااللومنيوم", link: "welding", desc: "" },
      { title: "قسم الأبواب", link: "doors", desc: "" },
      { title: "قسم القطع", link: "cutting", desc: "" },
      { title: "قسم اعمال الاستنلس استيل", link: "stainless", desc: "" },
      { title: "قسم القص و التطعيج", link: "bending", desc: "" },
      { title: "قسم الملفات والدرافيل", link: "rolling", desc: "" },
      { title: "قسم الجلفنة", link: "galvanizing", desc: "" },
      { title: "قسم الدهان", link: "Painting", desc: "" },
      { title: "قسم التسنين و المسامير", link: "thrading", desc: "" },
      { title: "قسم الخراطة و التفريز", link: "turning", desc: "" },
      { title: "قسم المكابس", link: "press", desc: "" },

      // { title: "قسم القطع بالماء", link: "water", desc: "" },
      // { title: "قسم مبيعات المواد الخام", link: "material", desc: "" },
    ],
  },
  app: `تطبيق`,
  user: `المستعمل`,
  list: `قائمة`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقالات`,
  post: `بريد`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `يزيد`,
  kanban: `كانبان`,
  general: `جنرال لواء`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level: `مستوى القائمة `,
  menu_level_2a: `مستوى القائمة 2 أ`,
  menu_level_2b: `مستوى القائمة 2 ب`,
  menu_level_3a: `مستوى القائمة 3 أ`,
  menu_level_3b: `مستوى القائمة 3 ب`,
  menu_level_4a: `مستوى القائمة 4 أ`,
  menu_level_4b: `مستوى القائمة 4 ب`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  item_external_link: `رابط خارجي للمادة`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
};

export default ar;
